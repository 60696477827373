@tailwind base;
@tailwind components;
@tailwind utilities;  

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

body{
	font-family: 'Montserrat', sans-serif;
}

*{
	margin: 0;
	padding: 0;
}
html{
	scroll-behavior: smooth;
}

html::-webkit-scrollbar{
	display: none;
}

html{
	scrollbar-width: none
}


